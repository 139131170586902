import React from "react";
import { Text } from "../Text";
import { Link } from "../Pages/styled";

interface MapProps {
  link: string;
  paddingBottom?: string;
}

export const Map = ({ link, paddingBottom }: MapProps) => {
  return (
    <Text fontSize="20px" paddingBottom={paddingBottom || "0px"}>
      <span
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Link href={link}>Directions</Link>
      </span>
    </Text>
  );
};
