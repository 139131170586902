import styled from "styled-components";
import { TextStyled } from "../Text/styled";
import { device } from "../../utils";

export const Title = styled(TextStyled)`
  font-family: Rouge Script;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  z-index: 1;
  color: #000;
  font-size: 50px;

  @media ${device.mobileS} {
    font-size: 60px;
  }

  @media ${device.tablet} {
    font-size: 150px;
  }

  @media ${device.laptop} {
    font-size: 200px;
  }

  @media ${device.laptopL} {
    font-size: 200px;
  }
`;

export const SmallTitle = styled(TextStyled)`
  font-family: Rouge Script !important;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  z-index: 1;
  color: #000;
  font-size: 60px;

  @media ${device.mobileS} {
    font-size: 60px;
  }

  @media ${device.tablet} {
    font-size: 60px;
  }

  @media ${device.laptop} {
    font-size: 60px;
  }

  @media ${device.laptopL} {
    font-size: 60px;
  }
`;

export const SmallFantasyTitle = styled(TextStyled)`
  font-family: "Podkova", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  z-index: 1;
  color: #000;
  font-size: 15px;

  @media ${device.mobileS} {
    font-size: 15px;
  }

  @media ${device.tablet} {
    font-size: 30px;
  }

  @media ${device.laptop} {
    font-size: 30px;
  }

  @media ${device.laptopL} {
    font-size: 30px;
  }
`;

export const Page1Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const Page2Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  @media ${device.mobileS} {
    justify-content: center;
  }

  @media ${device.tablet} {
    justify-content: center;
  }
`;

export const Page2Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  background-color: #ffffffa8;

  ${TextStyled} {
    font-family: "Podkova", sans-serif;
  }

  @media ${device.mobileS} {
    margin-bottom: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media ${device.tablet} {
    margin-bottom: 60px;
    padding: 100px;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  /* opacity: 0.5; */
`;

export const InvitationContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${TextStyled} {
    font-family: "Podkova", sans-serif;
  }

  @media ${device.mobileS} {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media ${device.tablet} {
    margin: 100px;
    margin-top: 0px;
    padding: 100px;
    padding-top: 0px;
    padding-bottom: 50px;
  }

  /* opacity: 0.5; */
`;

export const Link = styled.a`
  :link,
  :visited {
    color: blue;
  }
`;
