import React from "react";
import CountdownTimer from "../CountdownTimer";

export const WeddingCountdown = () => {
  const weddingTime = Date.UTC(2024, 0, 22, 10, 0, 0); // Date.UTC(2022, 3, 22)
  const currentTime = Date.now();

  return (
    <CountdownTimer
      count={(weddingTime - currentTime) / 1000}
      border
      showTitle
      responsive
      size={20}
      backgroundColor="none"
    />
  );
};
