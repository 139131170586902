import React from "react";
import { Page } from "./components/Page/Page";
import { AppStyled } from "./styled";

import Image1 from "./images/home3.png";
import Image2 from "./images/haldi-image.png";
import Image3 from "./images/wedding.png";
import Image4 from "./images/reception.png";
import Image5 from "./images/invitation.png";

import { Page1, Page2, Page3, Page4 } from "./components/Pages";
import { Invitation } from "./components/Pages/Invitation";

const searchParam = window.location.search;

const pages = [
  {
    img: Image1,
    content: <Page1 />,
    shouldShow: true,
  },
  {
    img: Image5,
    content: <Invitation />,
    shouldShow: true,
  },
  {
    img: Image2,
    content: <Page2 />,
    shouldShow: searchParam.includes("xndsncndcsnkmdad"),
  },
  {
    img: Image3,
    content: <Page3 />,
    shouldShow:
      searchParam.includes("xndsncndcsnkmdad") ||
      searchParam.includes("xjfsvfjslnvfjsklmas"),
  },
  {
    img: Image4,
    content: <Page4 />,
    shouldShow: true,
  },
];

export const App = () => {
  return (
    <AppStyled>
      {pages.map((page, index) => {
        return page.shouldShow ? (
          <Page key={index} index={index} page={page}></Page>
        ) : null;
      })}
    </AppStyled>
  );
};
