import React from "react";
import { Page2Wrapper, Page2Container, Title } from "./styled";
import { Text } from "../Text";
import { Map } from "../Map";

export const Page2: React.FC = () => {
  return (
    <Page2Wrapper>
      <div>
        <Title>Haldi</Title>
      </div>
      <div>
        <Page2Container>
          <Text fontSize="32px" paddingBottom="18px" fontWeight="900">
            21st JAN 2024
          </Text>
          <Text fontSize="20px" paddingBottom="2px" fontWeight="900">
            LUNCH
          </Text>
          <Text fontSize="32px" paddingBottom="18px" color="#454545">
            12:30 pm
          </Text>
          <Text fontSize="20px" paddingBottom="2px" fontWeight="900">
            MANDVA MAHURAT
          </Text>
          <Text fontSize="32px" paddingBottom="18px" color="#454545">
            2:00 pm
          </Text>
          <Text fontSize="20px" paddingBottom="2px" fontWeight="900">
            HALDI
          </Text>
          <Text fontSize="32px" paddingBottom="18px" color="#454545">
            6:30 pm
          </Text>
          <Text fontSize="20px" paddingBottom="2px" color="#454545">
            VENUE:
          </Text>
          <Text fontSize="20px" fontWeight="900">
            20 DOWNTOWN
          </Text>
          <Text fontSize="20px" paddingBottom="5px" color="#454545">
            2nd floor, Cambata Building/ Eros Cinema Building, <br />
            Jamshedji Tata Road, Churchgate, <br /> Mumbai 400020
          </Text>
          <Map link="https://maps.app.goo.gl/a4i6Horzg8its4VT9?g_st=ic" />
        </Page2Container>
      </div>
    </Page2Wrapper>
  );
};
