import React from "react";
import { Page2Wrapper, Page2Container, Title } from "./styled";
import { Text } from "../Text";
import { Map } from "../Map";

export const Page3: React.FC = () => {
  return (
    <Page2Wrapper>
      <div>
        <Title>Wedding</Title>
      </div>
      <div>
        <Page2Container>
          <Text fontSize="32px" paddingBottom="18px" fontWeight="900">
            22nd JAN 2024
          </Text>
          <Text fontSize="20px" paddingBottom="2px" fontWeight="900">
            BARAAT
          </Text>
          <Text fontSize="32px" paddingBottom="18px" color="#454545">
            2:00 pm
          </Text>
          <Text fontSize="20px" paddingBottom="2px" fontWeight="900">
            HAST MELAP
          </Text>
          <Text fontSize="32px" paddingBottom="18px" color="#454545">
            3:30 pm
          </Text>
          <Text fontSize="20px" paddingBottom="2px" color="#454545">
            VENUE:
          </Text>
          <Text fontSize="20px" fontWeight="900">
            CANTO
          </Text>
          <Text fontSize="20px" paddingBottom="5px" color="#454545">
            CLUB HOUSE, LEVEL P5, PENINSULA BUSINESS PARK, <br /> SENAPATI BAPAT
            MARG, <br /> LOWER PAREL, MUMBAI - 400 013
          </Text>
          <Map link="https://maps.app.goo.gl/pcAwu7WbSps1pCWw7" />
        </Page2Container>
      </div>
    </Page2Wrapper>
  );
};
