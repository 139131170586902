import React from "react";
import { WrapperPage } from "./styled";
import { Parallax } from "react-parallax";
import { Divider } from "../Divider";

export const Page: React.FC<{
  page: { img?: string; content: JSX.Element };
  index: number;
}> = ({ page, index }) => {
  return (
    <Parallax
      bgImage={page.img}
      bgImageStyle={{
        opacity: "0.5",
        minHeight: "750px",
      }}
      disabled
    >
      <WrapperPage>{page.content}</WrapperPage>
      {index !== 4 && <Divider />}
    </Parallax>
  );
};
