import React from "react";
import { Page2Wrapper, InvitationContainer, Title, SmallTitle } from "./styled";
import { Text } from "../Text";

export const Invitation: React.FC = () => {
  return (
    <Page2Wrapper>
      <div>
        <Title>Invitation</Title>
      </div>
      <div>
        <InvitationContainer>
          <Text fontSize="20px" paddingBottom="18px" paddingTop="10px">
            || SHRI MAHAVIRAYA NAMAH ||
          </Text>
          <Text fontSize="25px" paddingBottom="18px">
            Mrs. Rajeshree and Mr. Ajay Shah
          </Text>
          <Text fontSize="18px" paddingBottom="2px">
            Request your gracious presence on the wedding cermony of
          </Text>
          <SmallTitle>Adit</SmallTitle>
          <Text fontSize="14px" paddingBottom="2px">
            (GS/O Late Smt. Sudhaben Shah and Late Shri Kantilal Shah)
          </Text>
          <SmallTitle>with</SmallTitle>
          <SmallTitle>Dhwani</SmallTitle>
          <Text fontSize="14px" paddingBottom="18px">
            (D/O Mrs. Bina Shah and Mr. Rajesh Shah)
          </Text>
        </InvitationContainer>
      </div>
    </Page2Wrapper>
  );
};
