import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";

export default class CountdownTimer extends Component {
  timer: any;
  state: any;
  props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      count: parseInt(props.count, 10),
    };
  }

  componentDidMount() {
    this.handleStart();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleStart() {
    this.timer = setInterval(() => {
      const newCount = this.state.count - 1;
      this.setState({ count: newCount >= 0 ? newCount : 0 }, () => {
        if (this.state.count === 0) {
          this.props.onEnd();
          clearInterval(this.timer);
        }
      });
    }, 1000);
  }

  handleCountdown = (seconds: any) => {
    this.setState({
      count: seconds,
    });
  };

  format = (time: any) => {
    const {
      border,
      showTitle,
      direction,
      noPoints,
      color,
      backgroundColor,
      responsive,
      size,
      hideDay,
      hideHours,
      dayTitle,
      hourTitle,
      minuteTitle,
      secondTitle,
      labelSize,
    } = this.props as any;
    time = time < 0 ? 0 : time;
    let seconds: string | number = time % 60;
    let minutes: string | number = Math.floor(time / 60) % 60;
    let hours: string | number = Math.floor(time / 3600) % 24;
    let day: string | number = Math.floor(time / 86400);
    day = day < 10 ? `0${day}` : day;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    hours = hours < 10 ? `0${hours}` : hours;
    if (showTitle) {
      const borderClass = border ? "border" : "";
      const responsiveClass = responsive ? "responsive" : "";
      const classBox = `countBox ${direction}`;
      return (
        <div
          style={{ fontSize: `${size}px` }}
          className={`${classBox} ${borderClass} ${responsiveClass}`}
        >
          {!hideDay && (
            <div className={"countBoxItem"}>
              <div className={"count"} style={{ color, backgroundColor }}>
                <div style={{ fontSize: `${labelSize}px` }} className={"label"}>
                  {dayTitle}
                </div>
                {day}
              </div>
            </div>
          )}
          {!hideDay && !noPoints && <span className={"split"}>:</span>}

          {!hideHours && (
            <div className={"countBoxItem"}>
              <div className={"count"} style={{ color, backgroundColor }}>
                <div style={{ fontSize: `${labelSize}px` }} className={"label"}>
                  {hourTitle}
                </div>
                {hours}
              </div>
            </div>
          )}
          {!hideHours && !noPoints && <span className={"split"}>:</span>}

          <div className={"countBoxItem"}>
            <div className={"count"} style={{ color, backgroundColor }}>
              <div style={{ fontSize: `${labelSize}px` }} className={"label"}>
                {minuteTitle}
              </div>
              {minutes}
            </div>
          </div>

          {!noPoints && <span className={"split"}>:</span>}
          <div className="countBoxItem">
            <div className={"count"} style={{ color, backgroundColor }}>
              <div style={{ fontSize: `${labelSize}px` }} className={"label"}>
                {secondTitle}
              </div>
              {seconds}
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const { count } = this.state;
    const { className, id } = this.props;
    return (
      <div
        className={`root-react-component-countdown-timer ${className}`}
        id={id}
      >
        <div className="displayedTime">{this.format(count)}</div>
      </div>
    );
  }
}

(CountdownTimer as any).propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  count: PropTypes.number,
  border: PropTypes.bool,
  showTitle: PropTypes.bool,
  direction: PropTypes.oneOf(["right", "left"]),
  noPoints: PropTypes.bool,
  responsive: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.number,
  labelSize: PropTypes.number,
  hideDay: PropTypes.bool,
  hideHours: PropTypes.bool,
  dayTitle: PropTypes.string,
  hourTitle: PropTypes.string,
  minuteTitle: PropTypes.string,
  secondTitle: PropTypes.string,
  onEnd: PropTypes.func,
};

(CountdownTimer as any).defaultProps = {
  count: 0,
  border: false,
  showTitle: false,
  direction: "left",
  noPoints: false,
  color: "#000",
  backgroundColor: "#fff",
  responsive: false,
  size: 18,
  labelSize: 12,
  hideDay: false,
  hideHours: false,
  dayTitle: "Day",
  hourTitle: "Hour",
  minuteTitle: "Min",
  secondTitle: "Sec",
  className: "",
  id: "",
  onEnd: () => {},
};
