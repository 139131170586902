import React from "react";
import { Title, Page1Wrapper, SmallFantasyTitle } from "./styled";
import { WeddingCountdown } from "../Countdown";

export const Page1: React.FC = () => {
  return (
    <Page1Wrapper>
      <Title>Adit & Dhwani</Title>
      <SmallFantasyTitle>
        The final countdown to forever begins now.
      </SmallFantasyTitle>
      <WeddingCountdown />
    </Page1Wrapper>
  );
};
